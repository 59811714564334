/* THE FOUR STEPS TO BEING AN AWESOME UI ENGINEER (AND CO-CODER)
1. Try to keep custom styles to a minimum.
2. Be aware that these styles will affect your entire application.
3. Namespace your own custom styles with something
related to your app, for example, .spark-classname or .apollo-classname.
4. Use Canvas global variables for color, spacing and more.
They are CSS variables that are already globally available in the browser.
The full list is located here: https://canvas.prod.target.com/design-tokens. */

$mainHeaderHeight: 56px;

.topPage-layout-body {
  height: calc(100vh - $mainHeaderHeight);
}

.praxcss-height100vh {
  height: 100vh;
}

.C-TableAdvancedContainer {
  max-height: 58vh;
  overflow-y: auto;
}

.headerIconButton {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
}

.headerIconButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.topPage-layout-header {
  height: $mainHeaderHeight;
}

.white-header {
  color: white;
}

.horizontal-radio {
  .C-InputRadio__Option {
    display: inline-flex;
    padding-right: 24px
  }
}

.table-edit-button {
  z-index: 0;
}

.wrapped-text {
  word-break: break-word;
}

.full-width-table {
  .C-GridItem {
    min-width: 100%;
  }
}

@media (prefers-color-scheme: light) {
  .table-action-button {
    color: black;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0, 0, 0, 0);
  }
}

@media (prefers-color-scheme: dark) {
  .table-action-button {
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0, 0, 0, 0);
  }
}
